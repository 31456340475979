<template>
  <div class="justify-content-center flex flex-wrap" >
    <CardItem  class="md:w-4" :image="scaleImagen" title="Open a ticket on Discord to use it"
      description="">
    </CardItem>
    <div class="w-full">

    <!-- <select :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one</option>
        <option value="0">Sprite Iron Wall Helmet | Shield Block +15% </option>
        <option value="1">Sprite Iron Body | Shield Block +15%</option>
        <option value="2">Sprite Shield Breaker Helmet | Shield Block -15% </option>
        <option value="3">Sprite Shield Breaker Body | Shield Block -15%</option>
        <option value="4">Sprite Lightning Helmet | Attack Speed +20% </option>
        <option value="5">Sprite Lightning Body | Attack Speed +20%</option>
        <option value="6">Sprite Slow March Helmet | Attack Speed -40% </option>
        <option value="7">Sprite Slow March Body | Attack Speed -40%</option>
        <option value="8">Sprite Swift Sorcerer Helmet | Casting Speed +20% </option>
        <option value="9">Sprite Swift Sorcerer Body | Casting Speed +20%</option>
        <option value="10">Sprite Arcane Anchor Helmet | Casting Speed -20% </option>
        <option value="11">Sprite Arcane Anchor Body | Casting Speed -20%</option>
        <option value="12">Sprite Broken Helmet | Chance to receive Critical Hits +15% </option>
        <option value="13">Sprite Broken Body | Chance to receive Critical Hits +15%</option>
        <option value="14">Sprite Unbreakable Helmet |Hit Rate -15% </option>
        <option value="15">Sprite Unbreakable Body | Hit Rate -15%</option>
        <option value="16">Sprite Hunter Helmet | Evasion Rate -15% </option>
        <option value="17">Sprite Hunter Body | Evasion Rate -15%</option>
      </select> 
        <select v-if="itemsList2" :on-change="updateImage()" v-model="selectedOption" style="width: 400px;"
        class="form-select form-select-lg container flex justify-content-center mt-5 mb-3">
        <option value="">Choose one</option>
        <option v-for="(currItem, index) in itemsList2.items" :key="index" :value="index">
          {{ currItem.item_name[lang === 'br' ? 'pt' : lang] }}
        </option>
      </select>-->
    </div>
    <div class="flex justify-content-center w-full">
      <ButtonCustomGF v-if="(!scaleVal || (scaleVal && !claimed)) && verifiedAccount" id="buttonClaim"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3"
        :class="scaleVal || (scaleVal && !claimed)  ? '' : 'disabled '" @click="claimScale()" :msg="$t('claimAward')">
      </ButtonCustomGF>
      <ButtonCustomGF v-if="scaleVal && claimed && verifiedAccount" id="buttonClaim"
        class="flex justify-content-center mt-5 mb-3 w-full md:w-3 disabled" :msg="$t('claimed')"> </ButtonCustomGF>
    </div>
  </div>
  <modalMessage class="" v-if="showModal" :title="$t('messageInfo')" :status="status" :redirect="urlRedirect"
    :content="$t(modalContent)" @close="showModal = false"></modalMessage>
  
</template>

<script>
import bgImageFooter from '@/assets/images/1x/nav-bar-background.png';
import imageScale from '@/assets/images/items/secondScale/scale4Icon.png';
import ButtonCustomGF from '@/components/button-custom/ButtonCustom';
import CardItem from '@/components/cards/card-item/cardItem';
import modalMessage from '@/components/modal/modalMessage';
import { useLoading } from 'vue-loading-overlay';
import service from '@/mixins/service.js';
import { useI18n } from "vue-i18n";
import { ref } from "vue";
export default {
  components: {
    CardItem,
    ButtonCustomGF,
    
    modalMessage
  },
  mixins: [service],
  name: 'scale1-main',
  props: {
    msg: String,
    image: String,
    
    idPlayer: Number,
    scaleVal: Boolean,
    claimed: Boolean,
    verifiedAccount: Boolean
  },
  setup() {
    let modalContent = ref('')
    let urlRedirect = ref('')
    let status = ref('')
    let showModal = ref()
    let scaleImagen = ref(imageScale)
    let scaleImagenMain = ref(null);
    let bgImageFoo = ref(bgImageFooter)
    let itemData = ref({});
    let itemsList2 = ref({items:[]})

    const i18nLocale = useI18n();
    let lang = ref(i18nLocale.locale.value);
    const getItemData = async () => {
      const data = {
        path: '/items/55083',
        method: 'GET',
      }
      let itemDataTemp = await service.methods.callService(data)

      const data2 = {
        path: '/items/55084',
        method: 'GET',
      }
      
      let itemDataTemp2 = await service.methods.callService(data2)
      const data3 = {
        path: '/items/55085',
        method: 'GET',
      }
      
      let itemDataTemp3 = await service.methods.callService(data3)
      const data4 = {
        path: '/items/55086',
        method: 'GET',
      }
      
      let itemDataTemp4 = await service.methods.callService(data4)
      const data5 = {
        path: '/items/55087',
        method: 'GET',
      }
      
      let itemDataTemp5 = await service.methods.callService(data5)
      const data6 = {
        path: '/items/55088',
        method: 'GET',
      }
      
      let itemDataTemp6 = await service.methods.callService(data6)
      const data7 = {
        path: '/items/55089',
        method: 'GET',
      }
      
      let itemDataTemp7 = await service.methods.callService(data7)
      const data8 = {
        path: '/items/55090',
        method: 'GET',
      }
      
      let itemDataTemp8 = await service.methods.callService(data8)
      const data9 = {
        path: '/items/55091',
        method: 'GET',
      }
      
      let itemDataTemp9 = await service.methods.callService(data9)
      const data10 = {
        path: '/items/55092',
        method: 'GET',
      }
      
      let itemDataTemp10 = await service.methods.callService(data10)
      const data11 = {
        path: '/items/55093',
        method: 'GET',
      }
      
      let itemDataTemp11 = await service.methods.callService(data11)
      const data12 = {
        path: '/items/55094',
        method: 'GET',
      }
      
      let itemDataTemp12 = await service.methods.callService(data12)
      const data13 = {
        path: '/items/55095',
        method: 'GET',
      }
      
      let itemDataTemp13 = await service.methods.callService(data13)
      const data14 = {
        path: '/items/55096',
        method: 'GET',
      }
      
      let itemDataTemp14 = await service.methods.callService(data14)
      const data15 = {
        path: '/items/55097',
        method: 'GET',
      }
      
      let itemDataTemp15 = await service.methods.callService(data15)
      const data16 = {
        path: '/items/55098',
        method: 'GET',
      }
      
      let itemDataTemp16 = await service.methods.callService(data16)
      const data17 = {
        path: '/items/55099',
        method: 'GET',
      }
      
      let itemDataTemp17 = await service.methods.callService(data17)
      const data18 = {
        path: '/items/55100',
        method: 'GET',
      }
      
      let itemDataTemp18 = await service.methods.callService(data18)
        itemsList2.value['items'] = [itemDataTemp ,itemDataTemp2,itemDataTemp3,itemDataTemp4,itemDataTemp5,itemDataTemp6,itemDataTemp7,itemDataTemp8,itemDataTemp9,itemDataTemp10,itemDataTemp11,itemDataTemp12,itemDataTemp13,itemDataTemp14,itemDataTemp15,itemDataTemp16,itemDataTemp17,itemDataTemp18 ]
    }
    const getItemData2 = async () => {
      
    }
    getItemData();
    getItemData2();
    let user = ref({})
    user.value = JSON.parse(localStorage.getItem('user'))
    let selectedOption = ref('')
    const updateImage = async () => {
      const itemVal = itemsList2.value['items'][selectedOption.value];
      itemData.value = await itemVal;
      console.log(itemData.value)
      if (selectedOption.value == '') {
        scaleImagen.value = null;
        scaleImagenMain.value = null;
      } else {
        //scaleImagen.value = require(`@/assets/images/items/secondScale/scale4Icon${selectedOption.value}.png`);
        scaleImagen.value = `https://gfmemories.com/images/itemicon/${itemVal.ui_id_1?.toUpperCase()}.png`;
    }}
    const claimScale = async () => {
      const $loading = useLoading();
      const loader = $loading.show();
      //const itemVal = await itemsList2.value['items'][selectedOption.value];
      //console.log('hola claim',itemVal)
      try {
        const data = {
          path: '/auth/claimScale',
          data: { scale: 'scale4Second'},
          //data: { scale: 'scale4Second',selectedOption: itemVal.item_id },
          method: 'POST',
          headers: { 'Content-type': 'application/json; charset=UTF-8' }
        }
        const resp = await service.methods.callService(data)
        if (resp && resp.statusCode == 200) {
          loader.hide();
          modalContent.value = 'successSendItem';
          showModal.value = true
          urlRedirect.value = `scales`;
          status.value = "success"
        } else {
          loader.hide();
          modalContent.value = "errorOccurred";
          showModal.value = true
          status.value = 'failed'
        }
      } catch (error) {
        loader.hide();
        modalContent.value = "errorOccurred";
        showModal.value = true
        status.value = 'failed'
      }
    }
    return {
      bgImageFoo,
      scaleImagen,
      claimScale,
      modalContent,
      urlRedirect,
      status,
      showModal,
      selectedOption,
      updateImage,
      scaleImagenMain,
      itemData,
      itemsList2,
      lang
    };
  },
};
</script>

<style>
.button-custom-gf {
  font-family: Montserrat Regular;
  font-size: 16px;
  color: #FFFFFF;
  color: rgb(255, 255, 255);
}
</style>